import './css/style.css';

import { createApp } from 'vue';
// import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';

// Google Analytics (GA4) 설정
function loadGoogleAnalytics() {
  const gaId = import.meta.env.VITE_GA_ID;
  if (gaId) {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    window.gtag = gtag;
    gtag('js', new Date());
    gtag('config', gaId);
  } else {
    console.warn('Google Analytics ID가 설정되지 않았습니다.');
  }
}

const app = createApp(App);

// app.use(createPinia());
app.use(router);

app.mount('#app');

// Google Analytics 로드
loadGoogleAnalytics();