import HomeView from '../views/HomeView.vue';

// 동적 import를 위한 헬퍼 함수
const loadView = (year, viewName) => () => import(`../views/${year}/${viewName}.vue`);

export default [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/intro',
    name: 'intro',
    component: loadView('', 'IntroView'),
    meta: { title: '피어업 PEER-UP!' },
  },

  // 2023년도 경로
  {
    path: '/2023/participants_2023',
    name: 'participants_2023',
    component: loadView('2023', 'ParticipantsView_2023'),
  },
  {
    path: '/2023/build-up-1_2023',
    name: 'build-up-1_2023',
    component: loadView('2023', 'BuildUp1View_2023'),
  },
  {
    path: '/2023/build-up-2_2023',
    name: 'build-up-2_2023',
    component: loadView('2023', 'BuildUp2View_2023'),
  },
  {
    path: '/2023/ping-pong-chat_2023',
    name: 'ping-pong-chat_2023',
    component: loadView('2023', 'PingPongChatView_2023'),
  },
  {
    path: '/2023/hovering-flight_2023',
    name: 'hovering-flight_2023',
    component: loadView('2023', 'HoveringFlightView_2023'),
  },
  {
    path: '/2023/exhibition_2023',
    name: 'exhibition_2023',
    component: loadView('2023', 'ExhibitionView_2023'),
  },
  ...['1', '2', '3'].map((num) => ({
    path: `/2023/exhibition_2023/${num}`,
    name: `exhibition-2023-${num}`,
    component: loadView('2023', `Exhibition${num}View_2023`),
  })),

  // 2024년도 경로
  {
    path: '/2024/participants_2024',
    name: 'participants_2024',
    component: loadView('2024', 'ParticipantsView_2024'),
  },
  {
    path: '/2024/build-up-1_2024',
    name: 'build-up-1_2024',
    component: loadView('2024', 'BuildUp1View_2024'),
  },
  {
    path: '/2024/build-up-2_2024',
    name: 'build-up-2_2024',
    component: loadView('2024', 'BuildUp2View_2024'),
  },
  {
    path: '/2024/ping-pong-chat_2024',
    name: 'ping-pong-chat_2024',
    component: loadView('2024', 'PingPongChatView_2024'),
  },
  {
    path: '/2024/hovering-flight_2024',
    name: 'hovering-flight_2024',
    component: loadView('2024', 'HoveringFlightView_2024'),
  },
  {
    path: '/2024/ProFeedback_2024',
    name: 'ProFeedback_2024',
    component: loadView('2024', 'ProFeedbackView_2024'),
  },
  {
    path: '/2024/exhibition_2024',
    name: 'exhibition_2024',
    component: loadView('2024', 'ExhibitionView_2024'),
  },
  ...['1', '2', '3'].map((num) => ({
    path: `/2024/exhibition_2024/${num}`,
    name: `exhibition-2024-${num}`,
    component: loadView('2024', `Exhibition${num}View_2024`),
  })),
];