// src/router/index.js

import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path === '/intro') {
    document.title = 'PEER-UP!';
  } else {
    document.title = to.meta.title || 'PEER-UP!';
  }
  next();
});

export default router;
